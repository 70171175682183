<script>
import { mapState } from "vuex";

import {get_acsrf_token} from "@/methods";

export default {
  components: {
    AccountingScore: () => import('@/components/lazy/dashboard/SevDeskAccountingScore'),
    ProfitLoss: () => import('@/components/lazy/dashboard/SevDeskProfitLoss'),
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    }
  },
  created() {

  },
  destroyed() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-3">sevDesk</h4>

      <div class="row">
        <div class="col-lg-12">
          <ProfitLoss/>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12">
          <AccountingScore/>
        </div>
      </div>
    </div>
  </div>
</template>
